import styled from 'styled-components';

interface FlexProps {
  row?: boolean;
  alignItems?: 'stretch' | 'flex-start' | 'center' | 'flex-end';
  justifyContent?: 'flex-start' | 'center' | 'flex-end' | 'space-between';
  gap?: number;
}

export const Flex = styled.div.withConfig({
  shouldForwardProp: (props) => !['row', 'alignItems', 'justifyContent', 'gap'].includes(props),
})<FlexProps>`
  display: flex;
  gap: ${({ gap = 20 }) => gap}px;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  align-items: ${({ alignItems = 'stretch' }) => alignItems};
  justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
`;
