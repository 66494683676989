import { Colors } from '@app/styles/types/colors.type';
import styled, { css } from 'styled-components';

type SelfPosition = 'stretch' | 'start' | 'center' | 'end';

interface LayoutItemProps {
  area?: string;
  align?: SelfPosition;
  justify?: SelfPosition;
  visible?: boolean;
  color?: Colors;
}

export const LayoutItem = styled.div.withConfig({
  shouldForwardProp: (props) => !['area', 'align', 'justify', 'visible', 'color'].includes(props),
})<LayoutItemProps>`
  ${({ area }) => area && `grid-area: ${area};`}
  ${({ align }) => align && `align-self: ${align};`}
  ${({ justify }) => justify && `justify-self: ${justify};`}
  opacity: ${({ visible = true }) => (visible ? '1' : '0')};

  ${({ theme, color }) =>
    color &&
    css`
      & * {
        color: ${theme.colors[color]};
        &[fill] {
          fill: ${theme.colors[color]};
        }
        &[stroke] {
          stroke: ${theme.colors[color]};
        }
      }
    `}
`;
